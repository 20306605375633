<template>
  <div class="video-view-container" v-if="content">
    <div class="process_row">
      <div class="label">{{ content.title }}</div>
    </div>
    <video-player
      id="CustomVideoPlayer"
      class="video-player vjs-theme-forest"
      crossorigin="anonymous"
      playsinline
      controls
      autoplay
      v-if="videoSource"
      :sources="videoSource.sources"
      :poster="videoSource.poster"
      :playback-rates="[0.7, 1.0, 1.25, 1.5, 2.0]"
      :children="[
      'mediaLoader',
      'posterImage',
      'bigPlayButton',
      'loadingSpinner',
      'controlBar',
      'textTrackDisplay'
    ]"
    >
    </video-player>
    <div style="line-height: 30px" @click="capture">截图</div>
  </div>
</template>
<script>
import { courseApi } from '@/api'

export default {
  name: 'VideoView',
  props: ['contentId'],
  data () {
    return {
      content: null,
      videoSource: null
    }
  },
  watch: {
    contentId (newVal) {
      if (newVal) {
        this.init()
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      const res = await courseApi.content(this.contentId)
      this.content = res.data.data
      this.course = res.data.data.course
      this.videoSource = {
        sources: [
          {
            src: this.content.url,
            type: 'application/x-mpegURL'
          }
        ],
        poster: this.content.coverUrl
      }
    },
    capture () {
      // 获取video和canvas元素
      const video = document.getElementsByTagName('video')[0]
      const canvas = document.createElement('canvas')

      // 设置canvas尺寸与video一致
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight

      // 获取canvas的2d绘图上下文
      const context = canvas.getContext('2d')

      // 将当前video帧绘制到canvas上
      context.drawImage(video, 0, 0, canvas.width, canvas.height)

      // 将canvas内容转换为data URL，即Base64编码的图像
      const imageDataUrl = canvas.toDataURL('image/png')

      // 下载图片
      this.downloadImage(imageDataUrl)
    },
    downloadImage (dataUrl) {
      const link = document.createElement('a')
      link.href = dataUrl
      const now = new Date()
      link.download = this.content.title + '_' + now.toLocaleString() + '_截图.png'

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
</script>

<style scoped lang="less">
@import 'video.js/dist/video-js.css';

.video-view-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;

  .process_row {
    font-size: 14px;
    display: flex;
    color: #000;
    align-items: center;
    padding: 15px;
    background: #fff;
    box-shadow: 0 5px 5px rgba(92, 92, 92, 0.1);
    z-index: 1;

    .label {
      display: flex;
      flex: 1;
      font-weight: bold;
      font-size: 20px;

      .red {
        color: #FB1D1E;
      }
    }
  }

  .video-player {
    width: 100%;
    height: 500px;
  }
}
</style>
